<template>
  <div class="pt-12" style="background-color: white !important;">
    <v-container style="max-width: 990px">
      <h1 style="font-size: 26px !important">Professors particulars</h1>

      <v-row class="mt-4">
        <v-col cols="3">
          <v-card width="100%" height="100%">

          </v-card>
        </v-col>
        <v-col cols="9">
          <profe
            v-for="profe in profes"
            :profe="profe"
            class="mb-10"
          ></profe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Profe from "./components/Profe";

export default {
  name: "Profes",
  components: {Profe},
  data() {
    return {
      profes: [
        {
          name: "Francisco Javier",
          photo: "https://c.superprof.com/i/a/1210180/538126/600/20220802105436/profesor-irlandes-especializado-ingles-financiero-empresarial-disponible-para-dar-clases-madrid.jpg",
          subjects: ["Matemàtiques", "Física", "Tecnologia industrial", "Anglès", "Dibuix tècnic"],
          desc: "Profesor irlandés especializado en inglés financiero y empresarial, disponible para dar clases en Madrid."
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
