<template>
    <v-card class="rounded-xl d-flex pl-0"
            style="  box-shadow: 0 12px 24px rgb(2 30 84 / 10%) !important; max-width: 100%; position: relative "
            :height="height"
    >

      <v-avatar tile :size="height" class="rounded-xl"
      >
        <v-img :src="profe.photo"></v-img>
      </v-avatar>

      <div class="d-flex flex-column px-6 py-4 justify-start text-start" style="flex: 1">
        <span class="font-weight-bold title">{{ profe.name }}</span>
        <v-sheet style="max-width: 100%">
          <v-chip-group>
            <v-chip v-for="subject in profe.subjects"
                    color="rgb(74 162 255 / 42%)"
                    class="rounded font-weight-medium blue--text text--darken-4">
              {{ subject }}
            </v-chip>
          </v-chip-group>

          <span style="font-size: 14px; color: #454545">{{profe.desc}}</span>
        </v-sheet>
      </div>

    </v-card>
</template>

<script>
export default {
  name: "Profe",
  props: {
    profe: {
      required: true
    }
  },
  data() {
    return {
      height: 170
    }
  }
}
</script>

<style scoped>

</style>
